import { useEffect, useState, useMemo } from 'react'
import styled from 'styled-components'
import { useField } from 'formik'
import { useFactSheetContext } from 'context/fact-sheet-context'
import { useSubmitContext } from 'context/submit-context'
import { Card } from 'components/main/card'
import { Box } from 'components/container'
import { Loop, LoopAction } from 'components/form/loop'
import { Item } from 'components/form/item'
import { Component } from 'models/common'
import { AccommodationComponent } from './accommodation/accommodation-component'

const EmptyButton = styled.button`
    border: 0;
    background: none;
    color: #00B6FF;
    cursor: pointer;
    font-size: 13px;
`

export const AccommodationTypes: React.FC = () => {
    const [, meta] = useField('accommodationTypes')
    const [used, setUsed] = useState<string[]>([])
    const [rooms, setRooms] = useState<any>({})
    const [copyIndex, setCopyIndex] = useState<number>(-1)
    const [roomTypeCheck, setRoomTypeCheck] = useState<boolean>()
    const { header: { roomTypes } } = useFactSheetContext()
    const { registerAdditional } = useSubmitContext()

    const max = useMemo(() => Object.keys(rooms).length, [rooms])

    useEffect(() => {
        if (copyIndex < 0) {
            return
        }
        document.getElementById(`accommodation-component-index-${copyIndex + 1}`)?.scrollIntoView({
            block: 'start',
            behavior: 'smooth',
        })
        setCopyIndex(-1)
    }, [copyIndex])

    useEffect(() => {
        setRooms((roomTypes ?? []).reduce((current, type) => ({ ...current, [type]: type }), {}))
        setUsed(meta.value.map((val: any) => val.roomType).filter((val: string) => val))
    }, [meta.value, roomTypes])

    useEffect(() => {
        const filtered = (roomTypes ?? []).filter((roomType: string) => !used.includes(roomType))
        const roomCheck = filtered.length === 0
        setRoomTypeCheck(roomCheck)
        registerAdditional('accommodation', roomCheck, 'accommodationTypeInvalid')
    }, [used, roomTypes, registerAdditional])

    return (
        <Card type={Component.ACCOMMODATION_TYPES} required additional={roomTypeCheck}>
            <Loop name="accommodationTypes" defaultItem={{}} minCount={1} maxCount={max}>
                {(rootName, index, length) => (
                    <Box $row>
                        { index > 0 && (
                            <Item />
                        )}
                        <AccommodationComponent
                          rootName={rootName}
                          index={index}
                          used={used}
                          onCopy={setCopyIndex}
                          accommodationType={rooms}
                        />
                        {(length < max && index === length - 1) && (
                            <LoopAction index={index}>
                                {(addInternal) => (
                                    <EmptyButton
                                      type="button"
                                      onClick={addInternal}
                                    >
                                        +Add Room type
                                    </EmptyButton>
                                )}
                            </LoopAction>
                        )}
                    </Box>
                )}
            </Loop>
        </Card>
    )
}
