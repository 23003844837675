import { useCallback, useMemo } from 'react'
import styled from 'styled-components'
import { OnCopyCallback } from 'context/loop-context'
import { Image, Images } from 'components/image'
import { Box } from 'components/container'
import { LoopHandler } from 'components/form/loop'
import { Input, InputType } from 'components/form/items/input'
import { Select } from 'components/form/items/select'
import { Other } from 'components/form/other'
import { CheckBox } from 'components/form/items/check-box'
import { Radio } from 'components/form/items/radio'
import { Item } from 'components/form/item'
import { Note } from 'components/presets/note'
import { OtherInput } from 'components/presets/other-input'
import { DatePicker } from 'components/presets/date-picker'
import { Conditioning, Bathroom } from 'models/fact-sheet-data'
import { EntityComponent } from '../wrapper/entity-component'
import { ExtraBed } from './extra-bed'
import { ExtraKitchen } from './extra-kitchen'
import { ExtraFacilities } from './extra-fac'
import { ExtraChildren } from './extra-children'
import { Cleaning } from './cleaning'

const RoomBox = styled.div`
    background: #153C89;
    color: white;
    border-radius: 5px;
    font-size: 12px;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
`

const TypeWrapper = styled(Box)`
    column-gap: 5px;
`

interface AccommodationComponentProps {
    rootName: string
    index: number
    accommodationType: any
    used: string[]
    onCopy: OnCopyCallback
}

export const AccommodationComponent: React.FC<AccommodationComponentProps> = ({
    rootName,
    index,
    accommodationType,
    used,
    onCopy,
}) => {
    const mainRequired = `${rootName}-main`
    const bathroomRequired = `${rootName}-bathroom`
    const typology = `${rootName}-typology`

    const bathroom = {
        required: bathroomRequired,
    }

    const main = {
        required: mainRequired,
    }

    const headerOffset = useMemo(() => (
        document.getElementById('header-wrapper')?.offsetHeight ?? 0
    ), [])

    const renderAddIcon = useCallback((disabled: boolean) => {
        return <Image image={disabled ? Images.COPY_DISABLED : Images.COPY} width={15} />
    }, [])

    return (
        <Box $row id={`accommodation-component-index-${index}`} $scrollOffset={headerOffset + 20}>
            <LoopHandler
              index={index}
              copy={(data) => ({ ...data, roomType: null, size: null })}
              onCopy={onCopy}
              addAlways
              addIcon={renderAddIcon}
            >
                <Box $width="auto">
                    <TypeWrapper $length={2}>
                        <RoomBox>
                            R{index + 1}
                        </RoomBox>
                        <Select title="accommodationType" name={`${rootName}.roomType`} disabled={used} type={accommodationType} root="acc" disableTranslate flex required />
                    </TypeWrapper>
                    <Input title="sizeM2" name={`${rootName}.size`} type={InputType.NUMBER} />
                </Box>
            </LoopHandler>
            <CheckBox title="airCondition" name={`${rootName}.airCondition.checked`} required={main} />
            <Other name={`${rootName}.airCondition.checked`}>
                <Box $width="auto">
                    <Radio name={`${rootName}.airCondition.acType`} type={Conditioning} title="condition" row required />
                    <Other name={`${rootName}.airCondition.acType`} value="INDIVIDUAL" isAuto enableReset>
                        <EntityComponent rootName={`${rootName}.airCondition`} hideCheck />
                    </Other>
                    <Other name={`${rootName}.airCondition.acType`} value="CENTRAL_INDIV_CONTROL" isAuto enableReset>
                        <Box $row>
                            <Item />
                            <EntityComponent rootName={`${rootName}.airCondition`} hideCheck>
                                <DatePicker title="under" name={`${rootName}.airCondition.operationPeriod`} required />
                                <Input title="minimum" name={`${rootName}.airCondition.minHours`} type={InputType.NUMBER} required />
                            </EntityComponent>
                        </Box>
                    </Other>
                    <Other name={`${rootName}.airCondition.acType`} value="CENTRAL" isAuto enableReset>
                        <Box $row>
                            <Item />
                            <Item />
                            <EntityComponent rootName={`${rootName}.airCondition`} hideCheck>
                                <DatePicker title="under" name={`${rootName}.airCondition.operationPeriod`} required />
                                <Input title="minimum" name={`${rootName}.airCondition.minHours`} type={InputType.NUMBER} required />
                            </EntityComponent>
                        </Box>
                    </Other>
                </Box>
            </Other>
            <Box>
                <CheckBox title="bathroom" name={`${rootName}.bathroom.checked`} required={{ section: typology, required: mainRequired }}>
                    <Radio name={`${rootName}.bathroom.typology`} type={Bathroom} title="bathroom" required={{ required: typology }} />
                </CheckBox>
            </Box>
            <Box>
                <CheckBox title="bathroomAmenities" name={`${rootName}.bathroom.amenities`} required={{ section: bathroomRequired, required: mainRequired }}>
                    <Box $wrap $flex>
                        <CheckBox title="showerGel" name={`${rootName}.bathroom.showerGel`} required={bathroom} />
                        <CheckBox title="shampoo" name={`${rootName}.bathroom.shampoo`} required={bathroom} />
                        <CheckBox title="conditioner" name={`${rootName}.bathroom.conditioner`} required={bathroom} />
                        <CheckBox title="bodyLotion" name={`${rootName}.bathroom.bodyLotion`} required={bathroom} />
                        <CheckBox title="cottonBuds" name={`${rootName}.bathroom.cottonBuds`} required={bathroom} />
                        <CheckBox title="shavingSet" name={`${rootName}.bathroom.shavingSet`} required={bathroom} />
                        <CheckBox title="teethCleaningSet" name={`${rootName}.bathroom.teethCleaningSet`} required={bathroom} />
                        <CheckBox title="cosmeticMirror" name={`${rootName}.bathroom.cosmeticMirror`} required={bathroom} />
                        <CheckBox title="bathrobes" name={`${rootName}.bathroom.bathrobes`} required={bathroom} />
                        <CheckBox title="slippers" name={`${rootName}.bathroom.slippers`} required={bathroom} />
                        <CheckBox title="hairdryer" name={`${rootName}.bathroom.hairdryer`} required={bathroom} />
                    </Box>
                </CheckBox>
            </Box>
            <Box $row>
                <CheckBox title="telephone" name={`${rootName}.telephone`} required={main} />
                <CheckBox title="tv" name={`${rootName}.tv`} required={main} />
                <CheckBox title="satTv" name={`${rootName}.satTv`} required={main} />
                <CheckBox title="lcdSatTv" name={`${rootName}.lcdSatTv`} required={main} />
                <CheckBox title="gamingConsole" name={`${rootName}.gamingConsole`} required={main} />
                <EntityComponent title="miniFridge" rootName={`${rootName}.miniFridge`} required={main} />
                <EntityComponent title="miniBar" rootName={`${rootName}.miniBar`} showExtra extraTitle="filled" extraTool="dailyTooltip" required={main} />
                <EntityComponent title="electricKettle" rootName={`${rootName}.electricKettle`} required={main} />
                <EntityComponent title="teaAndCoffeeMakingFacilities" rootName={`${rootName}.teaAndCoffee`} required={main} />
                <EntityComponent title="coffeeMachine" rootName={`${rootName}.coffeeMachine`} required={main} />
                <EntityComponent title="safeBox" rootName={`${rootName}.safebox`} required={main} />
                <EntityComponent title="pillowMenu" rootName={`${rootName}.pillowMenu`} required={main} />
            </Box>
            <CheckBox title="extraBed" name={`${rootName}.extraBed.checked`} required={main}>
                <Other name={`${rootName}.extraBed.checked`} isAuto>
                    <Box $row>
                        <ExtraBed title="standardBed" rootName={rootName} type="extraStandardBed" />
                        <ExtraBed title="sofa" rootName={rootName} type="extraSofa" />
                        <ExtraBed title="bunkbed" rootName={rootName} type="extraBunkBed" />
                    </Box>
                </Other>
            </CheckBox>
            <Box $row>
                <CheckBox title="ironAndIroningBoard" name={`${rootName}.iron`} required={main} />
                <CheckBox title="sittingCorner" name={`${rootName}.sittingCorner`} required={main} />
                <CheckBox title="livingRoom" name={`${rootName}.livingRoom`} required={main} />
                <CheckBox title="dressRoom" name={`${rootName}.dressRoom`} required={main} />
                <CheckBox title="diningCornerRoom" name={`${rootName}.diningCorner`} required={main} />
                <CheckBox title="balcony" name={`${rootName}.balcony`} required={main} />
                <CheckBox title="terrace" name={`${rootName}.terrace`} required={main} />
                <CheckBox title="balconyOrTerrace" name={`${rootName}.balconyOrTerrace`} required={main} />
                <CheckBox title="frenchWindow" name={`${rootName}.frenchWindow`} required={main} />
                <CheckBox title="noBalcony" name={`${rootName}.noBalcony`} required={main} />
            </Box>
            <Box $row>
                <CheckBox title="other" name={`${rootName}.otherItems`} required={main} />
                <OtherInput name={`${rootName}.otherItems`} required />
            </Box>
            <Note name={`${rootName}.noteFirst`} />
            <ExtraKitchen root={rootName} />
            <ExtraFacilities root={rootName} />
            <ExtraChildren rootName={rootName} />
            <Cleaning root={rootName} />
        </Box>
    )
}
