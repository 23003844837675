import React, { createContext, useState, useMemo } from 'react'

export type OnRemoveCallback = (list: any[], index: number) => void
export type OnAddCallback = (item: any) => void
export type OnCopyCallback = (index: number) => void

export type AddFnc = (
    index: number,
    item: any, onAdd?:
    OnAddCallback,
    onCopy?: OnCopyCallback
) => void
type RemoveFnc = (index: number, onRemove?: OnRemoveCallback) => void

type CountType = number | undefined

export interface LoopContextProps {
    add: AddFnc
    setAdd: (param: AddFnc) => void
    remove: RemoveFnc
    setRemove: (param: RemoveFnc) => void
    counter: number
    setCounter: (counter: number) => void
    maxCount: CountType
    setMaxCount: (maxCount: CountType) => void
    minCount: CountType
    setMinCount: (minCount: CountType) => void
}

export const LoopContext = createContext<LoopContextProps>(
    {} as LoopContextProps,
)

type LoopContextProviderProps = React.PropsWithChildren

export const LoopContextProvider: React.FC<LoopContextProviderProps> = ({
    children,
}) => {
    const [add, setAdd] = useState<AddFnc>(() => () => {})
    const [remove, setRemove] = useState<RemoveFnc>(() => () => {})

    const [counter, setCounter] = useState(0)
    const [maxCount, setMaxCount] = useState<CountType>()
    const [minCount, setMinCount] = useState<CountType>()

    const providerContext = useMemo(() => ({
        add,
        setAdd,
        remove,
        setRemove,
        counter,
        setCounter,
        maxCount,
        setMaxCount,
        minCount,
        setMinCount,
    }), [
        add,
        setAdd,
        remove,
        setRemove,
        counter,
        setCounter,
        maxCount,
        setMaxCount,
        minCount,
        setMinCount,
    ])

    return (
        <LoopContext.Provider value={providerContext}>
            {children}
        </LoopContext.Provider>
    )
}
