import { styled } from 'styled-components'

export const width = 1340
export const gap = 6
export const columnGap = 30
export const headerGap = 10
export const count = 6
export const headerBackground = '#E6EEF4'
export const itemWidth = ''

export interface BoxProps {
    $row?: boolean
    $width?: string
    $flex?: boolean
    $wrap?: boolean
    $noGap?: boolean
    $length?: number
    $scrollOffset?: number
}

const singleItemWidth = (width - (columnGap * (count - 1))) / count

export const widthCalculator = (length: number) => (
    (singleItemWidth * length) + (columnGap * (length - 1))
)

export const Page = styled.div`
    width: 100%;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const Box = styled.div<BoxProps>`
    display: flex;
    column-gap: ${columnGap}px;
    row-gap: ${gap}px;
    flex-direction: ${({ $row }) => ($row ? 'column' : 'row')};
    width: ${({ $width }) => ($width ?? '100%')};
    align-items: flex-start;

    ${({ $flex }) =>
        $flex &&
        `
        flex: 1;
    `};

    ${({ $wrap }) =>
        $wrap &&
        `
        flex-wrap: wrap;
    `};

    ${({ $noGap }) =>
        $noGap &&
        `
        gap: 0;
    `};

    ${({ $length }) =>
        $length &&
        `
        width: ${widthCalculator($length)}px;
    `};

    ${({ $scrollOffset }) => `
        scroll-margin-top: ${$scrollOffset}px;
    `};
`
